import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

export default function Oss() {
  return (
    <Layout>
      <Seo title="amaretto.dev | books" description="book shelf" />
      wip
    </Layout>
  )
}
